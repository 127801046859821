import dynamic from 'next/dynamic';
import { GetStaticProps } from 'next';

import client from '@utils/apolloClient';
import { getHomepageEntryPoints } from '@queries/homepage.graphql';

import getEntryBoxInfo from '@utils/getEntryBoxInfo';

import Layout from '@components/Layout/Layout';

const ContentList = dynamic(
  () => import('@components/ContentList/ContentList')
);
const Description = dynamic(
  () => import('@components/Description/Description')
);
const SecondaryTitle = dynamic(
  () => import('@components/SecondaryTitle/SecondaryTitle')
);
const Strapline = dynamic(() => import('@components/Strapline/Strapline'));
const Title = dynamic(() => import('@components/Title/Title'));

type Error404Props = {
  collections: any;
  description: string;
  features: any;
  featureFlags: any;
  highlights: any;
  homepage: any;
  news: any;
  reviews: any;
  siteTitle: string;
  strapline: string;
  title: string;
};

const Error404 = ({
  collections,
  description,
  features,
  featureFlags,
  news,
  reviews,
  siteTitle,
  strapline,
  title,
}: Error404Props) => {
  return (
    <Layout
      featureFlags={featureFlags}
      pageDescription={description}
      pageTitle={title}
      pageType="article"
      section="Home"
      siteTitle={siteTitle}
      url="/404"
    >
      <Title>{title}</Title>
      <Strapline text={strapline} />

      <Description
        content={
          "We're sorry but it seems you're trying to visit a page that does not exist. Here's some other content you may be interested in..."
        }
      />

      <SecondaryTitle>Latest News</SecondaryTitle>
      <ContentList limit={true} posts={news || []} />
      <SecondaryTitle>Latest Features</SecondaryTitle>
      <ContentList limit={true} posts={features || []} />
      {featureFlags.collections ? (
        <>
          <SecondaryTitle>Latest Collections</SecondaryTitle>
          <ContentList limit={true} posts={collections || []} />
        </>
      ) : null}
      <SecondaryTitle>Latest Reviews</SecondaryTitle>
      <ContentList limit={true} posts={reviews || []} />
    </Layout>
  );
};

export default Error404;

export const getStaticProps: GetStaticProps = async () => {
  const configData = await import(`../siteconfig.json`);

  const {
    data: {
      CollectionItems: { items: collections },
      FeatureItems: { items: features },
      NewsItems: { items: news },
      ReviewItems: { items: reviews },
    },
  } = await client.query({
    query: getHomepageEntryPoints,
    variables: {
      length: 4,
      page: 1,
    },
  });

  return {
    props: {
      description: configData.default.description,
      collections: configData.featureFlags.collections
        ? collections.map((post) => getEntryBoxInfo(post, 'Collections'))
        : null,
      featureFlags: configData.featureFlags,
      features: features.map((post) => getEntryBoxInfo(post, 'Features')),
      news: news.map((post) => getEntryBoxInfo(post, 'News')),
      reviews: reviews.map((post) => getEntryBoxInfo(post, 'Reviews')),
      title: 'Page Not Found',
      strapline: `Sorry we couldn't find that...`,
      siteTitle: configData.default.title,
    },
  };
};
